import React from "react"

/*** COMPONENTS ***/
import Main from "../../components/main"
import SEO from "../../components/seo"
import "../../styles/project.css"
import tetrisHeader from "../../images/tetris.png"

/*** CONSTANTS ***/
import * as ROUTES from "../../constants/routes"

function openGithubTetris() {
  window.open(
    "https://github.com/johannagranstroem/TNM108-Machine-Learning-for-Social-Media",
    "_blank"
  )
}

const TetrisPage = () => (
  <Main>
    <SEO title="Tetris" />
    <div class="project-container">
      <div class="project-grid-item-11 ">
        <img alt="Header-Tetris" src={tetrisHeader} />
        <div class="project-circle">
          <p class="project-header-text-black">CHATBOT</p>
          <p class="project-header-text-grey">MACHINE LEARNING</p>
        </div>
      </div>
      <div class="project-grid-item-21">
        <p class="project-bulletpoint-text">Language</p>
        <p class="project-bulletpoint-text"> &#9642; Python</p>
      </div>
      <div class="project-grid-item-22">
        <p class="project-bulletpoint-text">Status</p>
        <p class="project-bulletpoint-text"> &#8226; Completed 2019-11-28</p>
      </div>
      <div class="project-grid-item-31">
        <p class="project-text-body-dark">
          With the help of the reinforcement learning method{" "}
          <b>Deep Q Networks</b>, the game Tetris was implemented. This project
          was completed together with Emilie Ho and Ronja Faltin.
          <br />
          <br />
          The goal was to train an AI to play and beat the game as a human
          would. The agent was trained by interacting with the environment. The
          first thing that happened in the process of training the agent, was
          that the agent was given a state from the environment. Depending on
          the state, the agent chooses an action to send back to the
          environment. Actions in return give rewards which can be positive,
          negative or zero. For this specific project, the actions were to move
          a brick left, right, rotate a brick or instant drop a brick. The goal
          was to train the agent to maximize the total reward it collects over
          an episode. An episode was one game from beginning to game over. For
          each episode, the agent learned which actions provided positive
          rewards and which actions to avoid.
          <br />
          <br />
          You can checkout the project on{" "}
          <button
            class="project-text-link-style"
            onClick={() => openGithubTetris()}
          >
            Github
          </button>
          !
          <br />
          <br />
        </p>
      </div>
      <div class="project-grid-item-61">
        <a class="project-text-back-to-projects" href={ROUTES.PROJECTS}>
          &#8592; Back to projects
        </a>
      </div>
    </div>
  </Main>
)

export default TetrisPage
